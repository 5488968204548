import styled, { css } from 'styled-components';
import ListItemStyle from '../../../styles/Components/ListItems/ListContainerStyles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { margin, padding, paddingY } from '../../../styles/atoms/spacing';
import { font } from '../../../styles/atoms/typography';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  width: 100%;
  ${padding(24, 32)}
  background-color: ${colors.base.white};
  border: 1px solid ${colors.gray[50]};
  border-radius: ${props => (props.active ? '0 0 10px 10px' : '10px')};

  ${atMinWidth.lg`
    flex: 1 0 25%;
    width: unset;
  `}

  ${atMinWidth.xl`
    ${padding(32)}
  `}

  .enterprise-icon {
    max-height: 63px;
    max-width: 100%;
    ${margin('auto')}
  }

  .pricing-btn {
    white-space: nowrap;
  }

  ${props =>
    props.active &&
    css`
    margin-top: 32px;

    &:before {
      content: 'Most Popular';
      position: absolute;
      width: 100%;
      bottom: 100%;
      left: 0;
      ${paddingY(10, 8)}
      ${font('overline', 'sm', '700')}
      color: ${colors.base.white};
      background-color: ${colors.green[500]};
      border-radius: 10px 10px 0 0;
    }

    ${atMinWidth.lg`
      margin-top: 0;
    `}
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;

export const CardTitle = styled.h2`
  ${font('display', 'sm', '700')}
  color: ${colors.gray[900]};

  ${atMinWidth.md`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.lg`
    ${font('display', 'sm', '700')}
  `}
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  ${font('display', 'sm', '700')}
  color: ${colors.gray[900]};
`;

export const Price = styled.span`
  ${font('display', 'lg', '700')}
`;

export const Billed = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  ${font('text', 'sm', '700')}
  color: ${colors.gray[900]};

  ${atMinWidth.md`
    ${font('text', 'md', '700')}
  `}

  ${atMinWidth.lg`
    ${font('text', 'sm', '700')}
  `}

  .custom-pricing {
    ${atMinWidth.lg`
      margin-top: 10px;
    `}
  }
`;

export const StrikethroughPrice = styled.div`
  ${font('text', 'lg', '400')}
  color: ${colors.gray[600]};
  ${props => props.annual && 'text-decoration: line-through;'}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}

  ${atMinWidth.lg`
    ${font('text', 'lg', '400')}
  `}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid ${colors.gray[50]};
  text-align: left;

  ${ListItemStyle} {
    margin-bottom: 0;

    p, 
    h2,
    h3,
    h4 {
      ${font('text', 'xs', '400')}
      color: ${colors.gray[900]};
    }

    .list-item {
      align-items: flex-start;

      .list-icon {
        margin-right: 15px;
      }

      .print-container {
        margin-top: 0.23rem;
      }
    }
  }
`;

export const ListTitle = styled.h3`
  ${font('text', 'xs', '400')}
  color: ${colors.gray[500]};
  text-align: center;
`;

export const Features = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding-top: 24px;
  border-top: 1px solid ${colors.gray[50]};

  ${ListItemStyle} .list-heading {
    display: inline-block;
    margin: 0;
    ${font('text', 'sm', '400')}
    text-align: left;

    & span,
    & p {
      ${font('text', 'sm', '400')}
      margin: 0
    }
  }
`;

export const FeaturesHeading = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${font('text', 'sm', '700')}
  color: ${colors.gray[600]};
  cursor: pointer;

  ${atMinWidth.sm`
    ${font('text', 'md', '700')}
  `}

  svg {
    transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: 300ms;
  }
`;
